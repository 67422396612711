<template>
  <div >
          <CCard>
            <h5 class="ml-3 mt-3">{{ $t("app.reset.pass.title") }}</h5>
            <CCardBody>
              <CForm novalidate @submit.prevent="submit">
                <div class="mandatory-color error-font">
                  <p v-if="validationMessage" class="text-danger">
                  <ul style="list-style-type:none;">
                    <li v-for="item in validationMessage" v-bind:key="item">{{ item }}</li>
                  </ul>

                  </p>
                  <p v-if="successMessage" class="text-center p-3 mb-2 bg-success text-white">
                    {{ successMessage }}
                  </p>
                  <p v-if="errorMessage" class="text-danger">
                    {{ errorMessage }}
                  </p>
                </div>

                <div role="group" class="form-group  d-flex mb-0">
                  <label class="col-xl-3 col-lg-4 col-sm-5 col-md-5 col-6 mt-2 font-weight-bold asterisk">
                    Old Password
                  </label>
                  <div class="col-xl-9 col-lg-8 col-sm-7 col-md-7 col-6 reset-screen">
                    <CInput placeholder="Old Password" type="password" v-model="oldPassword" required was-validated />
                    <show-errors class="mb-4" :control="$v.oldPassword" :field="$t('app.accountRegistation.name')" />
                  </div>
                </div>


                <div role="group" class="form-group  d-flex mb-0">
                  <label class="col-xl-3 col-lg-4 col-6 col-sm-5  col-md-5 mt-2 font-weight-bold asterisk">
                    New Password
                  </label>
                  <div class="col-xl-9 col-lg-8 col-6 col-sm-7 col-md-7 reset-screen">
                    <CInput :placeholder="'New Password'" type="password" required was-validated v-model="password" />
                    <show-errors class="mb-4" :control="$v.password" :field="$t('app.accountRegistation.name')" />
                  </div>
                </div>



                <div role="group" class="form-group  d-flex mb-0">
                  <label class="col-xl-3 col-lg-4 col-sm-5  col-6 col-md-5 mt-2 font-weight-bold asterisk">
                    {{ $t("app.same.confirm.password.profile") }}
                  </label>
                  <div class="col-xl-9 col-lg-8 col-6 col-sm-7 col-md-7 reset-screen">
                    <CInput :placeholder="$t('app.same.confirm.password.profile')" type="password" required
                      was-validated v-model="confirm_password" />
                    <show-errors class="mb-4" :control="$v.confirm_password"
                      :field="$t('app.accountRegistation.name')" />
                  </div>
                </div>


                <CCol col="12" class="text-right">
                  <CButton class="mr-2" color="secondary" @click="onCancel">
                    {{ $t("app.accountRegistation.cancel") }}</CButton>

                  <CButton color="primary" class="px-4" type="submit">
                    {{ $t("app.character.save.button") }}
                  </CButton>
                </CCol>
              </CForm>
            </CCardBody>
          </CCard>
  </div>
</template>
<script>
import { required, sameAs, maxLength } from "vuelidate/lib/validators";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import _ from "lodash";
import { UPDATE_PASSWORD } from "@/store/actions.type";
import { newPasswordValidation } from "@/shared/utils";
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "ResetPassword",
  components: { ShowErrors },
  data() {
    return {
      password: "",
      confirm_password: "",
      validationMessage: "",
      onSubmit: false,
      loading: false,
      showBottom: false,
      disabled: false,
      errorMessage: "",
      paramToken: "",
      successMessage: "",
      showLogin: "",
      email: "",
      oldPassword: "",
      loginPassword: "",
    };
  },
  validations: {
    password: { required, newPasswordValidation, maxLength: maxLength(30) },
    confirm_password: { sameAsPassword: sameAs("password") },
    oldPassword: { required },
  },
  methods: {
    /**
     * @method onCancel
     * @description Cancel all the submit data on click of cancel button
     * @author @Jeevan Poojary
     */
    onCancel() {
      this.$router.push({
        name: "users",
      });
    },
    /**
     * @method submit
     * @description Add login credentials and click on submit
     * @author @Jeevan Poojary
     */
    async submit() {
      this.$v.$touch();
      if (
        !this.$v.password.$invalid &&
        !this.$v.confirm_password.$invalid &&
        !this.$v.oldPassword.$invalid
      ) {
        let payload = {
          new_password: this.password,
          confirm_password: this.confirm_password,
          old_password: this.oldPassword,
        };
        let data = await this.$store.dispatch(UPDATE_PASSWORD, payload);

        if (_.get(data, "success", true)) {
          // localStorage.setItem("loginPassword", this.confirm_password);
          this.validationMessage = "";
          this.errorMessage = "";
          this.$toast.removeAll();
          this.$toast.success({
            message: _.get(data, "message", ""),
          });
          // this.$router.push({
          //   name: "users",
          // });

          let payload = {
            type: "admin",
          };

          localStorage.clear();
          this.$store.dispatch(LOGOUT, payload);
          setTimeout(() => {
            this.$router.push({
              name: "Login",
            });
          }, 2000);
        } else if (_.get(data, "code", 0) == 422) {
          this.$toast.error({
            message: _.get(data, "message", ""),
          });
          this.confirm_password = "";
          this.password = "";
          this.$v.confirm_password.$reset();
          this.$v.password.$reset();
        } else {
          this.errorMessage = _.get(data, "message", "Error");
        }
      }
    },
  },
  created() {
    this.loginPassword = localStorage.getItem("loginPassword");
    this.params = this.$route.params;
    if (_.get(this.params, "token", 0)) {
      let token = this.params.token;
      this.paramToken = token;
      this.email = this.params.email;
    }
  },
};
</script>
<style>
.reset-container {
  max-width: 750px !important;
}
@media (max-width: 426px) {
  .reset-container .d-flex {
    display: block !important;
  }
  .reset-container .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 100%;
  }
}
.reset-screen .form-group {
  margin-bottom: 0rem !important;
}
</style>

